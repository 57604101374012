import { AmbassadorCategory } from "@/js/resources";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../Form/Button";
import Input from "../Form/Input";
import Popup, { usePopup } from "../Popup";

interface Submit {
    name: string;
    display_name: string;
}

export default function EditAmbassadorCategoryPopup({ onSubmit, category }: {
    onSubmit: (data: Submit) => void | Promise<void>,
    category: AmbassadorCategory,
}) {
    const form = useForm<Submit>({
        defaultValues: category,
    });
    const { popPopup } = usePopup();

    return (
        <Popup title="Nagykövet hozzáadása" form={form} onSubmit={onSubmit} className="w-full" buttons={[
            <Button variant="text" onClick={popPopup}>Mégse</Button>,
            <Button variant="filled-tonal" submit>Mentés</Button>
        ]}>
            <div className="flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-3">
                    <Input label="Név" name="name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Megjelenített név" name="display_name" options={{
                        required: "Kötelező mező"
                    }} />
                </div>
            </div>
        </Popup>
    )
}