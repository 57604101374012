import { Ambassador } from "@/js/resources";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../Form/Button";
import Input from "../Form/Input";
import Popup from "../Popup";

function DropdownItem({
        children
}: {
    children: React.ReactNode
}) {
    return (
        <div className="flex items-center bg-surface">
            {children}
        </div>
    )
}

interface Submit<T extends string | never> {
    amount: number;
    note: string;
    donor_name: string;
    ambassador_id: T;
}

export default function NewDonationPopup<
    T extends Submit<U extends Ambassador[] ? string : never>,
    U extends Ambassador[] | undefined = undefined
>({
    onSubmit,
    ambassadors = undefined as U,
    defaultValues,
}: {
    ambassadors?: U;
    onSubmit: (data: T) => void;
    defaultValues?: T
}) {
    const form = useForm<T>({
        defaultValues: defaultValues as any
    });

    return (
        <Popup title="Új kampány" form={form} onSubmit={onSubmit} buttons={[
            <Button className="interactive-bg-surface-container" variant="text" submit>Mentés</Button>
        ]} className="w-full">
            <div className="flex flex-col gap-4">
                {ambassadors && (
                    <Input 
                        type="select"
                        label="Nagykövet" 
                        name="ambassador_id"
                        selectClassName="[&_option]:bg-surface [&_option]:text-on-surface"
                        options={{ required: "Kötelező mező" }}
                        choices={[{
                            label: "Kampányadomány",
                            value: "campaign"
                        }, ...ambassadors.map(({ id, name }) => ({ 
                            value: id.toString(),
                            label: name
                        }))]}
                    />
                )}
                <Input label="Összeg" name="amount" type="number" suffix="Ft" options={{ 
                    required: "Kötelező mező", 
                    min: { value: 0, message: "Az adományozott összeg nem lehet negatív" }, }} />
                <Input label="Megjegyzés" name="note" />
                <Input label="Adományozó neve" name="donor_name" />
            </div>
        </Popup>
    )
}