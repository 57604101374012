import { GlissadeInput, GlissadeInputProps, useGlissadeInput } from "@enymo/glissade";
import React from "react";

export interface InputProps extends GlissadeInputProps {
    label?: string;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    inputClassName?: string;
    error?: string;
}

export default function Input({
    label,
    className,
    prefix,
    suffix,
    inputClassName,
    disabled,
    name,
    options,
    error: errorProp,
    ...props
}: InputProps) {
    const { error } = useGlissadeInput({ name: name, disabled: disabled, error: errorProp });
    return (
        <label className="flex flex-col cursor-text">
            <div className={`
                relative
                peer group shrink-0
                flex items-center py-4 interactive-bg-surface-container-highest rounded-t-[4px]
                ${disabled ? "disabled" : ""}
                ${prefix ? "pl-3 pr-4 gap-4" : "px-4"}
                ${suffix ? "pr-3 pl-4 gap-4" : "px-4"}
                ${label ? "pt-6" : ""}
                ${className}
            `}>
                <span className={`
                    absolute bd-s top-2 
                    ${error !== undefined ? "text-error" : "text-on-surface-variant group-focus-within:!text-primary"}
                `}>
                    {label}{label && options?.required && <sup>*</sup>}
                </span>
                {prefix && <div className="shrink-0 size-[24px] flex items-center justify-center overflow-hidden fill-on-surface-variant">
                    {prefix}
                </div>}
                <div className={`flex h-full flex-1 relative shrink-0 items-center`}>
                    <GlissadeInput
                        className={`bg-transparent outline-none grow text-on-surface bd-l ${inputClassName ?? ""}`}
                        name={name}
                        disabled={disabled}
                        options={options}
                        {...props}
                    />
                </div>
                {suffix && <div className="shrink-0 size-[24px] flex items-center justify-center overflow-hidden fill-on-surface-variant">
                    {suffix}
                </div>}
            </div>
            <div className={`
                h-[1px] 
                ${error !== undefined ? "bg-error" : "bg-on-surface peer-focus-within:bg-primary peer-focus-within:h-[2px]"}
            `} />
            <div className="h-[1px] peer-focus-within:hidden" />
            {error && <div className="text-error bd-s mt-1 ml-1">{error}</div>}
        </label>
    )
}