import React from "react";
import Button from "../Form/Button";
import Popup from "../Popup";

export default function ConfirmChoicePopup({ children, onSubmit, onCancel, }: {
    children: React.ReactNode,
    onSubmit: () => void | Promise<void>,
    onCancel: () => void,
}) {

    return (
        <Popup buttons={[
            <Button className="interactive-bg-surface-container" variant="text" onClick={onCancel}>Nem</Button>,
            <Button className="interactive-bg-surface-container" variant="text" onClick={onSubmit}>Igen</Button>
        ]} className="w-full">
            {children}
        </Popup>
    )
}