import AmbassadorCards from "@/js/Components/AmbassadorCards";
import { DonationCardList } from "@/js/Components/DonationCardList";
import DonationForm from "@/js/Components/DonationForm";
import Button from "@/js/Components/Form/Button";
import { Image } from "@/js/Components/Image";
import Loader from "@/js/Components/Loader";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import { useCampaign } from "@/js/Providers/Public/CampaignProvider";
import { CAMPAIGN_IMAGE_ASPECT_RATIO, sortLinkedList, toCurrency } from "@/js/common";
import { AmbassadorCategory, usePublicCampaignAmbassadors, usePublicCampaignDonations } from "@/js/resources";
import React, { useMemo, useRef } from "react";
import { useParams } from "react-router";

export default function Campaign() {
    const { campaignId } = useParams();
    const [campaign] = useCampaign();
    const [ambassadors, { loading: ambassadorsLoading }] = usePublicCampaignAmbassadors({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId])
    });

    const categorizedAmbassadors = useMemo(() => {
        const categories = sortLinkedList(ambassadors.reduce((acc, ambassador) => {
            if (!acc.some(category => category.id === ambassador.category.id)) {
                acc.push(ambassador.category);
            }
            return acc;
        }, [] as AmbassadorCategory[]));
        return categories.map(category => ({
            categoryId: category.id,
            ambassadors: ambassadors.filter(ambassador => ambassador.category.id === category.id),
        }));
    }, [ambassadors]);

    const [donations] = usePublicCampaignDonations({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const donationFormRef = useRef<HTMLDivElement>(null);

    return (
        <div className="flex flex-col flex-1 pt-5 pb-12 max-w-[1200px] self-center w-full">
            {campaign ? (
                <div className="flex flex-col gap-12 flex-1">
                    <div className="flex flex-col gap-8" >
                        <div className="flex relative rounded-[15px] sm:rounded-[30px] overflow-hidden">
                            <Image
                                resourceImage={campaign.image ?? undefined}
                                className="w-full !rounded-[15px]"
                                aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                            />
                            <div className="flex flex-col gap-3 absolute w-full h-full bg-black/40 items-center justify-center p-2 sm:p-4">
                                <h1 className="ttl-m min-[480px]:hd-s sm:hd-l text-center text-white font-semibold tracking-wide">{campaign.name}</h1>
                                {campaign.active && (
                                    <Button onClick={() => donationFormRef.current?.scrollIntoView({
                                        behavior: "smooth"
                                    })}>
                                        Támogatom
                                    </Button>
                                )}
                            </div>
                        </div>
                        <span className="bd-l whitespace-pre-wrap text-justify">{campaign.description}</span>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between gap-3">
                                    <span>Összesen:</span>
                                    <span>{toCurrency(campaign.donation_amount)}</span>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <span>Célösszeg:</span>
                                    <span>{toCurrency(campaign.donation_goal)}</span>
                                </div>
                            </div>
                            <div className="flex flex-1" >
                                <StaticProgressBar progress={campaign.donation_amount / campaign.donation_goal * 100} variant="primary" />
                            </div>
                        </div>
                    </div>
                    {(ambassadors.length > 0 && (
                        <div className="flex flex-col gap-8">
                            {categorizedAmbassadors.map(({ categoryId, ambassadors }) => (
                                <div key={categoryId} className="flex flex-col gap-5">
                                    <h1 className="ttl-l text-primary">{ambassadors[0].category.display_name}</h1>
                                    <AmbassadorCards ambassadors={ambassadors} loading={ambassadorsLoading} isPublic />
                                </div>
                            ))}
                        </div>
                    ))}
                    {campaign.active && <DonationForm ref={donationFormRef} />}
                    {donations.length > 0 && (
                        <div className="flex flex-col gap-5">
                            <DonationCardList
                                donations={donations}
                                title="Adományozóink"
                                titleClassName="ttl-l text-primary"
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-col flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            )}
        </div>
    )
}