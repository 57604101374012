import BackButton from "@/js/Components/BackButton";
import DonationsTable from "@/js/Components/DonationsTable";
import Button from "@/js/Components/Form/Button";
import IconButton from "@/js/Components/IconButton";
import { ImageInput } from "@/js/Components/ImageInput";
import Loader from "@/js/Components/Loader";
import PropertyForm from "@/js/Components/PropertyForm";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import { useUser } from "@/js/Providers/UserProvider";
import { AMBASSADOR_IMAGE_ASPECT_RATIO, toCurrency } from "@/js/common";
import { useAmbassadorDonations, useCampaignAmbassadors, useCampaigns } from "@/js/resources";
import CopyIcon from "@/svg/content-copy.svg?react";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

interface Submit {
    name: string,
    description_short: string,
    description_long: string,
    donation_goal: number,
    email: string,
    end_date: Date | null,
}

export default function Ambassador() {
    const { user } = requireNotNull(useUser());
    assertNotNull(user);
    const { campaignId, ambassadorId } = useParams();
    const [, { refresh: refreshCampaign }] = useCampaigns({
        id: Number(campaignId),
    });
    const [ambassador, { update }] = useCampaignAmbassadors({
        params: useMemo(() => ({
            campaign: campaignId
        }), [campaignId]),
        id: Number(ambassadorId),
    });

    const [donations] = useAmbassadorDonations({
        params: useMemo(() => ({
            ambassador: ambassadorId,
        }), [ambassadorId]),
    });

    const [editMode, setEditMode] = useState(false);
    const form = useForm<Submit>();

    useEffect(() => {
        if (!ambassador) return;
        setFormValues(form, {
            donation_goal: ambassador.donation_goal,
            email: ambassador.email,
            end_date: ambassador.end_date?.toISOString().split('T')[0] ?? null,
            description_long: ambassador.description_long,
            description_short: ambassador.description_short,
            name: ambassador.name
        });
    }, [ambassador]);

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await update(data);
        setEditMode(false);
        await refreshCampaign();
    }

    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

    const handleFileSelect = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        update({
            image: { file }
        }, 'immediate', {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress ?? 0) * 100;
                if (progress < 100) {
                    setUploadProgress(progress);
                }
                else {
                    setUploadProgress(undefined);
                }
            }
        });
    }

    const handleDeleteImage = () => {
        update({
            image: null,
        }, 'immediate');
    }

    return (
        <div className="flex flex-col flex-1 py-5 gap-10 max-w-[1200px] w-full self-center">
            {!ambassador ? (
                <div className="flex felx-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            ) : (
                <>
                    <div className="flex flex-col gap-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-5">
                                <BackButton to={`/app/campaigns/${campaignId}`} />
                                <h1 className="hd-s">Nagykövet kezelése</h1>
                            </div>
                            <IconButton
                                to={`/public/organizations/${user.organization_id}/campaigns/${campaignId}/ambassadors/${ambassadorId}`}
                                linkType="new-tab"
                                variant="filled-tonal"
                            >
                                <OpenInNewIcon />
                            </IconButton>
                        </div>
                        <div className="flex items-center gap-3 self-end">
                            <span className="text-[14px]">Link másolása</span>
                            <IconButton onClick={() => navigator.clipboard.writeText(ambassador.short_link)} variant="outlined">
                                <CopyIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col sm:flex-row gap-10 sm:items-start">
                            <ImageInput
                                resourceImage={ambassador.image ?? undefined}
                                uploadProgress={uploadProgress}
                                handleFileSelect={handleFileSelect}
                                onDelete={handleDeleteImage}
                                onSetPosition={(position) => update({
                                    image: {
                                        ...ambassador.image,
                                        position,
                                    }
                                })}
                                aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                                className="w-full sm:w-[300px]"
                            />
                            <Form form={form} onSubmit={handleSubmit} className="flex-1">
                                <div className="flex flex-col gap-3 flex-1">
                                    <PropertyForm
                                        editable={editMode}
                                        properties={[
                                            {
                                                label: "E-mail",
                                                value: ambassador.email,
                                                input: {
                                                    name: "email",
                                                    options: {
                                                        required: "Kötelező mező",
                                                    }
                                                }
                                            },
                                            {
                                                label: "Név",
                                                value: ambassador.name,
                                                input: {
                                                    name: "name",
                                                    options: {
                                                        required: "Kötelező mező",
                                                    }
                                                }
                                            },
                                            {
                                                label: "Rövid leírás",
                                                value: ambassador.description_short ?? undefined,
                                                input: {
                                                    name: "description_short",
                                                    type: "textarea",
                                                }
                                            },
                                            {
                                                label: "Leírás",
                                                value: ambassador.description_long,
                                                cutLength: 300,
                                                input: {
                                                    name: "description_long",
                                                    type: "textarea",
                                                    options: {
                                                        required: "Kötelező mező",
                                                    },
                                                    inputClassName: "min-h-[200px]",
                                                }
                                            },
                                            {
                                                label: "Célösszeg",
                                                value: toCurrency(ambassador.donation_goal),
                                                input: {
                                                    name: "donation_goal",
                                                    type: "number",
                                                    options: {
                                                        min: { value: 0, message: "A célösszeg nem lehet negatív" },
                                                    },
                                                    suffix: "Ft",
                                                }
                                            },
                                            {
                                                label: "Kampány vége",
                                                value: ambassador.end_date?.toLocaleDateString('hu'),
                                                input: {
                                                    name: "end_date",
                                                    type: "date",
                                                }
                                            },
                                        ]}
                                    />
                                    <div className="flex justify-end gap-3">
                                        <Button variant="outline" onClick={() => setEditMode(!editMode)}>
                                            {editMode ? "Mégse" : "Szerkesztés"}
                                        </Button>
                                        {editMode && (
                                            <Button /* variant="secondary" */ submit>Mentés</Button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-col gap-1">
                                <h2 className="ttl-l">Adományok</h2>
                                <span className="bd-s">Tipp: adományokat a kampánykezelő oldalon keresztül tudsz hozzáadni vagy törölni.</span>
                            </div>
                            <div className="flex gap-4 items-center">
                                <h2>Összesen: {toCurrency(ambassador.donation_amount)}</h2>
                                {ambassador.donation_goal && (
                                    <div className="flex flex-1">
                                        <StaticProgressBar progress={ambassador.donation_amount / ambassador.donation_goal * 100} variant="primary" />
                                    </div>
                                )}
                            </div>
                            <DonationsTable donations={donations} />
                        </div>
                    </div>
                </>
            )}
        </div >
    );
}