import { toCurrency } from "@/js/common";
import { Price } from "@/js/resources";
import { RadioGroup, RadioGroupProps, useGlissadeChoice, useGlissadeInput } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import Input, { InputProps } from "./Input";

interface PriceInputPropsCommon extends Omit<RadioGroupProps<string>, "children"> {
    prices: Price[],
    className?: string,
}

interface PriceInputPropsExtended extends PriceInputPropsCommon {
    allowCustom: true,
    customInputProps: InputProps,
    customError?: string,
}

interface PriceInputPropsImplementation extends PriceInputPropsCommon {
    allowCustom?: true,
    customInputProps?: InputProps,
    customError?: string,
}


export default function PriceInput(props: PriceInputPropsCommon): JSX.Element;
export default function PriceInput(props: PriceInputPropsExtended): JSX.Element;
export default function PriceInput({
    prices,
    className,
    error: errorProp,
    name,
    allowCustom,
    customInputProps,
    customError,
    ...props
}: PriceInputPropsImplementation) {
    const { error } = useGlissadeInput({ name: name, error: errorProp });
    const form = useFormContext();

    const selectedPrice = form.watch(name ?? "");

    return (
        <>
            <RadioGroup
                name={name}
                error={errorProp}
                {...props}
            >
                <div className={classNames("flex flex-col gap-1", className)}>
                    <div className="flex gap-3 items-center flex-wrap justify-center">
                        <span className={classNames({
                            "text-error": error
                        })}>Összeg:</span>
                        {prices.map(price => (
                            <PriceRadio key={price.id} price={price} error={error} />
                        ))}
                        {allowCustom && <PriceRadio key="custom" price="custom" error={error} />}
                    </div>
                    {error && <div className="bd-s ml-1 text-error">{error}</div>}
                </div>
            </RadioGroup>
            {allowCustom && selectedPrice === "custom" && <Input
                name={name}
                options={customInputProps?.options}
                error={customError}
                {...customInputProps}
            />}
        </>
    )
}

function PriceRadio({ price, error }: {
    price: Price | "custom",
    error?: string,
}) {
    const value = price === "custom" ? "custom" : price.id;
    const { checked, ...radioProps } = useGlissadeChoice({ value });

    return (
        <label className={classNames("flex items-center justify-center text-nowrap cursor-pointer p-3 interactive-bg-surface-container rounded-xl", {
            "bg-tertiary-container text-on-tertiary-container": checked,
            "border border-error": error,
        })}>
            <input className="hidden" type="radio" id={value} checked={checked} {...radioProps} />
            <span>{price === "custom" ? "Egyéni összeg" : toCurrency(price.unit_amount)}</span>
        </label>
    );
}