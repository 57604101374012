import React from "react";
import { Link } from "react-router-dom";
import { AMBASSADOR_IMAGE_ASPECT_RATIO, toCurrency } from "../common";
import { Ambassador } from "../resources";
import CutText from "./CutText";
import { Image } from "./Image";
import StaticProgressBar from "./StaticProgressBar";

export default function AmbassadorCard({ ambassador, width, to }: {
    ambassador: Ambassador,
    width?: number,
    to: string,
}) {
    const campaignEnded = ambassador.end_date && ambassador.end_date.getTime() < Date.now();
    return (
        <div className="flex flex-col shrink-0 gap-3 bg-surface-container min-w-[230px] border border-outline-variant rounded-[12px] overflow-hidden pb-4">
            <div className="flex flex-col">
                <Image 
                    resourceImage={ambassador.image ?? undefined}
                    to={to}
                    aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                    className="w-full rounded-none rounded-t-[10px]"
                />
                <div className={`
                    flex flex-col
                    bg-voneszo-orange text-white px-4 py-2 text-[14px] font-medium rounded-b-[12px]
                    text-ellipsis whitespace-nowrap overflow-hidden
                    ${!campaignEnded && "!bg-secondary-container !text-on-secondary-container"}
                `}>
                    <span className="text-ellipsis">{campaignEnded ? 
                        "A gyűjtés lezárult." : 
                        ambassador.end_date ? 
                            `Még ${Math.ceil((ambassador.end_date.getTime() - Date.now()) / (1000 * 60 * 60 * 24))} napig tart.` : 
                            "A gyűjtés folyamatban van."
                    }</span>
                </div>
            </div>
            <div className="flex flex-col gap-3 px-4 justify-between flex-1">
                <div className="flex flex-col gap-3">
                    <Link 
                        className="text-[18px] interactive-bg-surface-container text-primary w-min rounded-md whitespace-nowrap text-ellipsis px-1 ml-[-4px]"
                        to={to}
                    >
                        {ambassador.name}
                    </Link>
                    <CutText text={ambassador.description_short ?? ambassador.description_long} maxLength={100} />
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex justify-between text-[15px] text-on-surface whitespace-pre-wrap">
                        <span>{campaignEnded ? "Összesen" : "Eddig"}:</span>
                        <span>{toCurrency(ambassador.donation_amount)}</span>
                    </div>
                    {ambassador.donation_goal && (<>
                        <StaticProgressBar 
                            progress={ambassador.donation_amount / ambassador.donation_goal * 100} 
                            variant={campaignEnded ? "tertiary" : "secondary"}
                        />
                        <div className="flex justify-between text-[15px] text-on-surface whitespace-pre-wrap">
                            <span>Cél:</span>
                            <span>{toCurrency(ambassador.donation_goal)}</span>
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    )
}