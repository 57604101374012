import LeftIcon from "@/svg/chevron-left.svg?react";
import RightIcon from "@/svg/chevron-right.svg?react";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { toCurrency } from "../common";
import useMeasuring from "../Hooks/MeasuringHook";
import { Donation, RecurringDonation } from "../resources";
import IconButton from "./IconButton";

interface BaseDonation {
    id: number | string,
    amount: number,
    donee?: string,
    donor_name: string | null,
    note?: string,
    created_at: Date,
    type: "simple" | "recurring"
}

interface BaseDonationCardListProps {
    donations: BaseDonation[],
    title?: string,
    titleClassName?: string,
    withoutNote?: boolean,
    hideDonee?: boolean
}

export function DonationCardList({ 
    donations: donationsProp, 
    recurringDonations: recurringDonationsProp,
    ...props
}: {
    donations: Donation[],
    recurringDonations?: RecurringDonation[]
} & Omit<BaseDonationCardListProps, "donations">) {

    const simpleDonations = useMemo(() => donationsProp.map(donation => ({
        id: donation.id,
        amount: donation.amount,
        donee: donation.donationable_type === "App\\Models\\Campaign" ? "Kampányadomány" : donation.ambassador_name,
        donor_name: donation.donor_name,
        note: donation.note,
        created_at: donation.created_at,
        type: "simple" as const
    })), [donationsProp]);

    const recurringDonations = useMemo(() => !recurringDonationsProp ? [] : recurringDonationsProp.map(donation => ({
        id: `recurring_${donation.id}`,
        amount: donation.donation_amount,
        donor_name: donation.donor_name,
        created_at: donation.created_at,
        type: "recurring" as const
    })), [recurringDonationsProp])

    const donations = useMemo(() => [
        ...simpleDonations,
        ...recurringDonations,
    ].sort((a, b) => b.created_at.getTime() - a.created_at.getTime()), [simpleDonations, recurringDonations])

    return (
        <BaseDonationCardList donations={donations} {...props} />
    )
}

function BaseDonationCardList({ 
    donations,
    title,
    titleClassName,
    withoutNote,
    hideDonee
}: BaseDonationCardListProps) {
    const [page, setPage] = useState(1);
    const minCardWidth = withoutNote ? 200 : 300;
    const gap = 16;

    const [ref, { width }] = useMeasuring<HTMLDivElement>();

    const cardCount = useMemo(() => {
        let cardCount = 1;
        while (cardCount * minCardWidth + (cardCount - 1) * gap < width) {
            cardCount++;
        } 
        return Math.max(1, cardCount - 1);
    }, [width, minCardWidth, gap]);
    const cardWidth = cardCount == 1 ? "100%" : Math.max(minCardWidth, (width - (cardCount - 1) * gap) / cardCount);

    const maxPage = Math.ceil(donations.length  / cardCount);

    useEffect(() => {
        setPage(1);
    }, [cardCount])

    return (
        <div className="flex flex-col gap-2 overflow-clip pb-2">
            <div className="flex justify-between items-center">
                <span className={titleClassName}>{title}</span>
                <div className="flex self-end gap-1">
                    <IconButton disabled={page == 1} onClick={() => setPage(page - 1)} variant="filled-tonal">
                        <LeftIcon />
                    </IconButton>
                    <IconButton disabled={page == maxPage} onClick={() => setPage(page + 1)} variant="filled-tonal">
                        <RightIcon />
                    </IconButton>
                </div>
            </div>
            <div ref={ref} className="flex flex-row w-full transition-transform" style={{ 
                gap,
                transform: `translateX(-${((width ?? 0) + gap) * (page - 1)}px)`
            }}>
                {donations.map((donation) => (
                    <DonationCard 
                        key={donation.id} 
                        donation={donation}
                        style={{ 
                            width: cardWidth,
                        }}
                        withoutNote={withoutNote}
                        hideDonee={hideDonee}
                    />
                ))}
            </div>
        </div>
    )

}


function DonationCard({
    donation: { amount, donee, donor_name, note, created_at, type },
    className,
    style,
    withoutNote = false,
    hideDonee = false
}: {
    donation: BaseDonation,
    className?: string,
    style?: React.CSSProperties,
    withoutNote?: boolean,
    hideDonee?: boolean
}) {
    return (
        <div className={classNames(className, "rounded-lg bg-surface-container p-4 flex flex-col gap-2 shrink-0 shadow-md")} style={style}>
            <div className="flex flex-col gap-1">
                <span className="ttl-m font-medium line-clamp-1">{donor_name ?? "Anonim"}</span>
                <span className="ttl-l font-bold line-clamp-1">{toCurrency(amount)}</span>
            </div>
            <div className="flex flex-col gap-1">
                {!hideDonee && (
                    <span className="bd-l line-clamp-1">{withoutNote ? created_at.toLocaleDateString('hu', {
                        dateStyle: "long"
                    }) : donee}</span>
                )}
                <span className="bd-m text-ellipsis line-clamp-2">{withoutNote ? (
                    type === "simple" ? "Egyszeri adomány" : "Rendszeres adomány"
                ) : note}</span>
            </div>
        </div>
    )
}