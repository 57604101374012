import { CAMPAIGN_IMAGE_ASPECT_RATIO, EmailRegex, ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO, toCurrency } from '@/js/common';
import CutText from '@/js/Components/CutText';
import { DonationCardList } from '@/js/Components/DonationCardList';
import DonationForm from '@/js/Components/DonationForm';
import Button from '@/js/Components/Form/Button';
import Input from '@/js/Components/Form/Input';
import PriceInput from '@/js/Components/Form/PriceInput';
import { Image } from '@/js/Components/Image';
import Loader from '@/js/Components/Loader';
import StaticProgressBar from '@/js/Components/StaticProgressBar';
import { Checkbox } from '@/js/glidespec';
import useStripeElementsProps from '@/js/Hooks/StripeElementsHook';
import useStripeHandleSubmit from '@/js/Hooks/StripeSubmissionHook';
import useRedirectUrl from '@/js/Hooks/SuccessfulDonationRedirectUrlHook';
import { useDonationSuccessful, useOrganization } from '@/js/Layouts/Public';
import { usePublicOrganizationCampaigns, usePublicOrganizationDonations, useRecurringDonations, useStripePrices } from '@/js/resources';
import { Form } from '@enymo/react-form-component';
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { route } from 'ziggy-js';

const donationTypes = ["one-time", "recurring"] as const;
type DonationType = typeof donationTypes[number];

interface Submit {
    name: string,
    anonymous: boolean,
    email: string,
    donation_size: string,
}

export default function Organization() {
    const { organizationId } = useParams();
    const navigate = useNavigate();

    const donationRef = useRef<HTMLDivElement>(null);

    const [, setDonationSuccess] = useDonationSuccessful();
    const redirectUrl = useRedirectUrl(true);
    const [organization, { loading }] = useOrganization();
    const [searchParams, setSearchParams] = useSearchParams();
    const donationType: DonationType = searchParams.get('type') as DonationType ?? "one-time";
    const [campaigns, { loading: campaignsLoading }] = usePublicOrganizationCampaigns({
        params: useMemo(() => ({ organization: Number(organizationId) }), [organizationId]),
        sorter: (a, b) => a.active ? -1 : 1,
    });
    const [donations] = usePublicOrganizationDonations({
        params: useMemo(() => ({ organization: Number(organizationId) }), [organizationId]),
        sorter: (a, b) => a.created_at > b.created_at ? -1 : 1,
    })

    const [recurringDonations] = useRecurringDonations({
        params: useMemo(() => ({ organization: Number(organizationId) }), [organizationId]),
        sorter: (a, b) => (a.end_at === null && b.end_at === null) ? (a.created_at > b.created_at ? -1 : 1) : ((a.end_at ?? new Date()) > (b.end_at ?? new Date()) ? -1 : 1),
    })

    const [imageToShow, setImageToShow] = useState<number>();

    useEffect(() => {
        if (!donationTypes.includes(donationType)) {
            setSearchParams({ type: donationTypes[0] });
            return;
        }
        if (donationType !== searchParams.get('type')) {
            setSearchParams({ type: donationType });
        }
    }, [donationType, setSearchParams, searchParams]);

    const setNextImage = useCallback(() => {
        if (!organization?.gallery_images) return;
        setImageToShow(prev => {
            if (prev === undefined) return 0;
            return (prev + 1) % organization.gallery_images.length;
        });
    }, [organization?.gallery_images, setImageToShow]);

    const setTimer = useCallback(() => {
        setTimeout(() => {
            setNextImage();
            setTimer();
        }, 5000);
    }, [setNextImage]);

    useEffect(() => {
        if (organization?.gallery_images && imageToShow === undefined) {
            setNextImage();
            setTimer();
        }
    }, [organization?.gallery_images, setTimer, setNextImage, imageToShow]);

    const [prices, { loading: pricesLoading }] = useStripePrices({
        sorter: (a, b) => a.unit_amount - b.unit_amount
    });
    const form = useForm<Submit>();

    const donationSize = form.watch("donation_size");
    const recurringName = form.watch("name");
    const amount = prices.find(price => price.id === donationSize)?.unit_amount;

    const elementProps = useStripeElementsProps({
        mode: "subscription",
        amount: (amount ?? 0) * 100,
    });

    const handleSubmit = useStripeHandleSubmit(
        (data: Submit) => axios.post(route("public.organizations.recurring-donations.donate", {
            organization: organization?.id,
        }), {
            ...data
        }).then(response => response.data),
        () => {
            setDonationSuccess("success")
            navigate(redirectUrl);
        },
        [organization?.id, donationType, redirectUrl]
    )


    return loading ? (
        <div className='flex flex-col self-center m-10 items-center gap-5'>
            <Loader className="size-12 self-center" />
            <h1 className="bd-l text-on-surface text-center leading-5">Az oldal betöltése folyamatban van...</h1>
        </div>
    ) :(
        <div className="flex flex-col flex-1 pt-5 pb-12 max-w-[1200px] self-center w-full gap-10">
            <div className="flex flex-col gap-10">
                <div className="flex w-full overflow-hidden rounded-[30px] grow relative sm:w-3/4 sm:self-center">
                    <div className='flex w-full transition-transform duration-500' style={{
                        transform: `translateX(-${imageToShow ?? 0}00%)`,
                    }}>
                        {organization?.gallery_images.map((image) => (
                            <Image
                                key={image.id}
                                resourceImage={image}
                                aspectRatio={ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO}
                                className="w-full h-min"
                            />
                        ))}
                    </div>
                    <div className='absolute bottom-9 right-1/2 translate-x-1/2'>
                        <Button onClick={() => donationRef.current?.scrollIntoView({
                            behavior: "smooth",
                        })}>Támogatom</Button>
                    </div>
                    <div className='absolute bottom-3 flex gap-3 z-10 right-1/2 transform translate-x-1/2'>
                        {Array.from({ length: organization?.gallery_images.length ?? 0 }, (_, i) => (
                            <button
                                type="button"
                                key={i}
                                onClick={() => setImageToShow(i)}
                                className={classNames("size-3 rounded-full cursor-pointer shadow-md", {
                                    "bg-primary": i === imageToShow,
                                    "bg-surface-container": i !== imageToShow,
                                })}
                            />
                        ))}
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <h2 className="ttl-l text-primary">Rólunk</h2>
                    <p className="bd-l whitespace-pre-wrap text-justify">{organization?.description_long}</p>
                </div>
            </div>
            <div className='flex flex-col gap-5' ref={donationRef}>
                <h2 className="ttl-l text-primary">Adományozás</h2>
                <div className="flex border border-outline w-min rounded-full h-10 overflow-hidden self-center">
                    <button
                        onClick={() => setSearchParams({ type: "one-time" })}
                        className={classNames("flex items-center justify-center pl-5 pr-4 interactive-bg-surface border-r border-r-outline !border-r-solid ", {
                            "!bg-secondary-container": donationType === "one-time"
                        })}>Egyszeri</button>
                    <button
                        onClick={() => setSearchParams({ type: "recurring" })}
                        className={classNames("flex items-center justify-center pr-5 pl-4 interactive-bg-surface", {
                            "!bg-secondary-container": donationType === "recurring"
                        })}>Rendszeres</button>
                </div>
                {donationType === "recurring" && (
                    <span className='bd-m text-secondary text-center'>Havi rendszerességű támogatás</span>
                )}
                {donationType === "one-time" ? (
                    <DonationForm />
                ) : (
                    <Elements {...elementProps}>
                        <ElementsConsumer>
                            {({ elements, stripe }) => (
                                <Form form={form} onSubmit={handleSubmit(elements ?? undefined, stripe)} className="flex flex-col gap-3">
                                    <div className='flex flex-col gap-3'>
                                        <Input name='name' label="Név" options={{
                                            required: "A név megadása kötelező",
                                        }} />
                                        {recurringName && (
                                            <div className='flex flex-col gap-1'>
                                                <Checkbox name="anonymous">
                                                    Nyilvánosan ne jelenjen meg a nevem
                                                </Checkbox>
                                                <p className="bd-s ml-1">
                                                    A szervezők továbbra is látni fogják a neved.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <Input name='email' label="Email" options={{
                                        required: "Az email megadása kötelező",
                                        pattern: {
                                            value: EmailRegex,
                                            message: "Az email cím formátuma nem megfelelő"
                                        }
                                    }} />
                                    {!pricesLoading ? (
                                        <PriceInput prices={prices} name='donation_size' className="self-center" options={{
                                            required: "Az összeg kiválasztása kötelező",
                                        }} />
                                    ) : (
                                        <Loader className='self-center size-10' />
                                    )}
                                    {amount !== undefined && (
                                        <div style={{
                                            margin: "20px 0",
                                        }}>
                                            <PaymentElement options={{
                                                layout: "accordion",
                                            }} />
                                        </div>
                                    )}
                                    <div className='flex flex-col gap-2 items-center'>
                                        <span className="bd-s text-center">
                                            Az adományozás gombra kattintással elfogadod az <a href={organization?.gdpr_url} target="_blank" rel="noreferrer" className="text-primary">adatvédelmi irányelveket</a>.
                                        </span>
                                        <Button variant='filled-tonal' submit>Adományozás</Button>
                                    </div>

                                </Form>
                            )}
                        </ElementsConsumer>
                    </Elements>
                )}
            </div>
            {(donations.length > 0 || recurringDonations.length > 0) && donations.length> 0 && (
                <DonationCardList 
                    donations={donations}
                    recurringDonations={recurringDonations}
                    title='Adományozóink'
                    titleClassName="ttl-l text-primary" 
                    withoutNote
                />
            )}
            {(campaigns.length > 0 || campaignsLoading) && (
                <div className='w-full flex flex-col gap-5'>
                <h2 className="ttl-l text-primary">Kampányaink</h2>
                {campaignsLoading ? (
                    <Loader className='self-center w-10' />
                ) : (
                    <SimpleBar className='w-full overflow-x-auto'>
                        <div className='w-full flex gap-2'>
                            {campaigns.map(campaign => (
                                <div
                                    key={campaign.id}
                                    className='flex flex-col w-full sm:max-w-[400px] shrink-0 bg-surface-container rounded-[25px] border border-outline m-1'
                                >
                                    <Image 
                                        resourceImage={campaign.image ?? undefined}
                                        aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                                        className='w-full h-min rounded-none rounded-t-[24.5px]'
                                        to={`/public/organizations/${organization?.id}/campaigns/${campaign.id}`}
                                    />
                                    <div className={classNames('flex items-center justify-center p-2 w-full rounded-b-[25px]', {
                                        'bg-primary-container': campaign.active,
                                        'bg-voneszo-orange text-white': !campaign.active,
                                    })}>
                                        {campaign.active ? "A kampány aktív" : "A kampány lezárult"}
                                    </div>
                                    <div className='flex flex-col gap-4 px-3 pb-8 pt-3 justify-between h-full'>
                                        <div className='flex flex-col gap-4'>
                                            <Link 
                                                to={`/public/organizations/${organization?.id}/campaigns/${campaign.id}`} 
                                                className={classNames('ttl-m text-primary interactive-bg-surface-container w-fit p-1 rounded-lg -m-1')}
                                            >
                                                    {campaign.name}
                                            </Link>
                                            <CutText className='bd-m' text={campaign.description} maxLength={300} />
                                        </div>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex items-center justify-between'>
                                                <span className='bd-m'>Eddig:</span>
                                                <span className='bd-m'>{toCurrency(campaign.donation_amount)}</span>
                                            </div>
                                            <div className='w-full px-2'>
                                                <StaticProgressBar 
                                                    progress={campaign.donation_amount / campaign.donation_goal * 100}
                                                    variant='secondary'
                                                />
                                            </div>
                                            <div className='flex items-center justify-between'>
                                                <span className='bd-m'>Cél:</span>
                                                <span className='bd-m'>{toCurrency(campaign.donation_goal)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </SimpleBar>
                )}
            </div>
        )}
        </div>
    );
}