import AmbassadorCards from "@/js/Components/AmbassadorCards";
import BackButton from "@/js/Components/BackButton";
import DonationsTable from "@/js/Components/DonationsTable";
import Button from "@/js/Components/Form/Button";
import IconButton from "@/js/Components/IconButton";
import { ImageInput } from "@/js/Components/ImageInput";
import Loader from "@/js/Components/Loader";
import { usePopup } from "@/js/Components/Popup";
import AlertPopup from "@/js/Components/Popups/AlertPopup";
import ConfirmChoicePopup from "@/js/Components/Popups/ConfirmChoicePopup";
import EditAmbassadorCategoryPopup from "@/js/Components/Popups/EditAmbassadorCategoryPopup";
import NewAmbassadorCategoryPopup from "@/js/Components/Popups/NewAmbassadorCategoryPopup";
import NewAmbassadorPopup from "@/js/Components/Popups/NewAmbassadorPopup";
import NewDonationPopup from "@/js/Components/Popups/NewDonationPopup";
import PropertyForm from "@/js/Components/PropertyForm";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import Switch from "@/js/Components/Switch";
import { useUser } from "@/js/Providers/UserProvider";
import { CAMPAIGN_IMAGE_ASPECT_RATIO, destroyLinkedListResource, moveLinkedListResource, sortLinkedList, toCurrency } from "@/js/common";
import { useCampaignAmbassadorCategories, useCampaignAmbassadors, useCampaignDonations, useCampaigns } from "@/js/resources";
import ChevronDown from "@/svg/chevron-down.svg?react";
import CopyIcon from "@/svg/content-copy.svg?react";
import DonwloadIcon from "@/svg/download.svg?react";
import EditIcon from "@/svg/edit.svg?react";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import TrashIcon from "@/svg/trash.svg?react";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { route } from "ziggy-js";

interface Submit {
    name: string;
    description: string;
    donation_goal: number;
    donation_success_message: string;
    donation_email_template: FileList | null;
    campaign_end_email_template: FileList | null;
}

export default function Campaign() {
    const { user } = requireNotNull(useUser());
    assertNotNull(user);
    const { campaignId } = useParams();
    const [campaign, { update }] = useCampaigns({ id: Number(campaignId) });

    const [_ambassadorCategories, {
        store: storeCategory,
        update: updateCategory,
        destroy: destroyCategory,
    }] = useCampaignAmbassadorCategories({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    })
    const ambassadorCategories = useMemo(() => sortLinkedList(_ambassadorCategories), [_ambassadorCategories]);

    const [donations, { 
        store: storeDonation,
        destroy: destroyDonation,
    }] = useCampaignDonations({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const [ambassadors, { loading: ambassadorsLoading, store: storeAmbassador }] = useCampaignAmbassadors({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const categorizedAmbassadors = useMemo(() => {
        if (ambassadors.length === 0 || ambassadorCategories.length === 0) return {};
        return Object.fromEntries(
            ambassadorCategories.map(({ id }) => [id, ambassadors.filter(ambassador => ambassador.category_id === id)])
        )
    }, [ambassadors, ambassadorCategories]);

    const { addPopup, popPopup } = usePopup();
    const navigate = useNavigate();

    const [editMode, setEditMode] = useState(false);
    const form = useForm<Submit>();

    const [showNotApproved, setShowNotApproved] = useState(false);

    useEffect(() => {
        if (!campaign) return;
        setFormValues(form, {
            ...campaign,
            campaign_end_email_template: undefined,
            donation_email_template: undefined,
        });
    }, [campaign]);

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await update({
            ...data,
            campaign_end_email_template: data.campaign_end_email_template === null ? null : data.campaign_end_email_template?.[0] ?? undefined,
            donation_email_template: data.donation_email_template === null ? null : data.donation_email_template?.[0] ?? undefined,
        });
        setEditMode(false);
    }

    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

    const handleFileSelect = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        update({
            image: { file },
        }, 'immediate', {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress ?? 0) * 100;
                if (progress < 100) {
                    setUploadProgress(progress);
                }
                else {
                    setUploadProgress(undefined);
                }
            }
        });
    }

    const handleDeleteImage = () => {
        update({
            image: null,
        }, 'immediate');
    }

    const handleAddDonation = () => {
        addPopup(
            <NewDonationPopup
                ambassadors={ambassadors}
                onSubmit={async (data) => {
                    await storeDonation(data);
                    popPopup();
                }}

            />
        );
    }

    const handleAddAmbassador = (id: number) => () => {
        addPopup(
            <NewAmbassadorPopup
                onSubmit={async (data) => {
                    const ambassador = await storeAmbassador({
                        ...data,
                        category_id: id,
                    });
                    popPopup();
                    navigate(`/app/campaigns/${campaignId}/ambassadors/${ambassador.id}`);
                }}
            />
        );
    }

    const handleCreateCategory = () => {
        addPopup(
            <NewAmbassadorCategoryPopup
                onSubmit={async (data) => {
                    const newCategory = await storeCategory({
                        ...data,
                        previous_id: data.previous_id === "first" ? "first" : Number(data.previous_id),
                    });
                    const previousCategory = ambassadorCategories.find((category) => (
                        category.id !== newCategory.id && 
                        category.previous_id === newCategory.previous_id
                    ));
                    if (previousCategory) {
                        updateCategory(
                            previousCategory.id,
                            {
                                previous_id: newCategory.id,
                            },
                            "local-only"
                        )
                    }
                    popPopup();
                }}
                categories={ambassadorCategories}
            />
        );
    }

    const handleMoveCategory = moveLinkedListResource(_ambassadorCategories, updateCategory);

    const handleDestroyCategory = (id: string | number) => {
        if ((categorizedAmbassadors[id]?.length ?? 0) > 0) {
            addPopup(
                <AlertPopup onCancel={popPopup}>
                    A kategória törlése előtt először törölj minden gyűjtést, ami ehhez a kategóriához tartozik.
                </AlertPopup>
            )
            return;
        }
        destroyLinkedListResource(_ambassadorCategories, destroyCategory, updateCategory)(id);
    }

    const handleEditCategory = (id: string | number) => {
        const category = ambassadorCategories.find((category) => category.id === id);
        if (!category) return;
        addPopup(
            <EditAmbassadorCategoryPopup
                onSubmit={async (data) => {
                    await updateCategory(category.id, data);
                    popPopup();
                }}
                category={category}
            />
        )
    }

    const handleDeleteDonation = (id: number) => {
        addPopup(
            <ConfirmChoicePopup 
                onSubmit={async () => {
                    await destroyDonation(id);
                    popPopup();
                }}
                onCancel={popPopup}
            >
                Biztosan törölni szeretnéd az adományt? Nem fogod tudni visszaállítani.
            </ConfirmChoicePopup>
        )
    }

    const handleDonwloadData = async () => {
        const response = await axios.get(route('campaigns.donations.export', { campaign: campaignId }), {
            responseType: 'blob',
        });
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition']?.split('filename=')[1] ?? 'adatok.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="flex flex-col py-5 gap-10 flex-1 max-w-[1200px] w-full self-center">
            {!campaign ? (
                <div className="flex items-center justify-center flex-1">
                    <Loader className="w-12" />
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center">
                        <div className="flex gap-5 items-center">
                            <BackButton to="/app/campaigns" />
                            <h1 className="hd-s">Kampány kezelése</h1>
                        </div>
                        <IconButton
                            to={`/public/organizations/${user.organization_id}/campaigns/${campaignId}`}
                            linkType="new-tab"
                            variant="filled-tonal"
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex flex-row-reverse justify-between sm:items-center gap-3">
                            <div className="flex flex-col items-end justify-between sm:flex-row gap-3 text-right">
                                <div className="flex items-center gap-3">
                                    <span className="text-[14px]">Adatok letöltése</span>
                                    <IconButton onClick={handleDonwloadData} variant="outlined">
                                        <DonwloadIcon />
                                    </IconButton>
                                </div>
                                <div className="flex items-center gap-3">
                                    <span className="text-[14px]">Link másolása</span>
                                    <IconButton onClick={() => navigator.clipboard.writeText(campaign.short_link)} variant="outlined">
                                        <CopyIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="flex flex-col items-end justify-between sm:flex-row gap-3">
                                <div className="flex items-center gap-3">
                                    <span className="text-[14px]">{campaign.active ? "Aktív" : "Inaktív"}</span>
                                    <Switch
                                        value={campaign.active}
                                        onChange={(active) => update({ active }, 'immediate')}
                                    />
                                </div>
                                <div className="flex items-center gap-3">
                                    <span className="text-[14px]">{campaign.published ? "Nyilvános" : "Nem nyilvános"}</span>
                                    <Switch
                                        value={campaign.published}
                                        onChange={(published) => update({ published }, 'immediate')}
                                    />
                                </div>
                            </div>
                        </div>
                        <ImageInput
                            resourceImage={campaign.image ?? undefined}
                            uploadProgress={uploadProgress}
                            handleFileSelect={handleFileSelect}
                            onDelete={handleDeleteImage}
                            onSetPosition={(position) => update({
                                image: {
                                    ...campaign.image,
                                    position,
                                }
                            })}
                            aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                            className="w-full h-min"
                        />
                        <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-3">
                            <PropertyForm
                                editable={editMode}
                                properties={[
                                    {
                                        label: "Név",
                                        value: campaign.name,
                                        input: {
                                            name: "name",
                                            options: { required: "Kötelező mező" }
                                        },
                                    },
                                    {
                                        label: "Leírás",
                                        value: campaign.description,
                                        cutLength: 300,
                                        input: {
                                            name: "description",
                                            type: "textarea",
                                            inputClassName: "min-h-[300px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        },
                                    },
                                    {
                                        label: "Célösszeg",
                                        value: toCurrency(campaign.donation_goal),
                                        input: {
                                            name: "donation_goal",
                                            type: "number",
                                            options: { required: "Kötelező mező" },
                                            suffix: "Ft",
                                        },
                                    },
                                    {
                                        label: "Adományozás utáni üzenet",
                                        value: campaign.donation_success_message,
                                        cutLength: 200,
                                        input: {
                                            name: "donation_success_message",
                                            type: "textarea",
                                            inputClassName: "min-h-[100px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        }
                                    },
                                    {
                                        label: "Adományozás utáni e-mail",
                                        value: campaign.donation_email_template?.name,
                                        input: {
                                            inputType: "file",
                                            name: "donation_email_template",
                                            label: "{useLabel}",
                                            multiple: false,
                                            accept: "text/html",
                                            fileNames: campaign.donation_email_template?.name,
                                            previewUrl: `/app/campaigns/${campaignId}/preview?template=donation`,
                                        }
                                    },
                                    {
                                        label: "Kampány vége e-mail",
                                        value: campaign.campaign_end_email_template?.name,
                                        input: {
                                            inputType: "file",
                                            name: "campaign_end_email_template",
                                            label: "{useLabel}",
                                            multiple: false,
                                            accept: "text/html",
                                            fileNames: campaign.campaign_end_email_template?.name,
                                            previewUrl: `/app/campaigns/${campaignId}/preview?template=campaign_end`,
                                        }
                                    }
                                ]}
                            />
                            <div className="flex justify-end gap-3">
                                <Button variant="outline" onClick={() => setEditMode(!editMode)}>
                                    {editMode ? "Mégse" : "Szerkesztés"}
                                </Button>
                                {editMode && (
                                    <Button variant="filled-tonal" submit>Mentés</Button>
                                )}
                            </div>
                        </Form>
                        <div className="flex flex-col gap-5">
                            <h1 className="ttl-l text-primary">Adományok</h1>
                            <div className="flex gap-6 items-center">
                                <h2>Összesen: {toCurrency(campaign.donation_amount)}</h2>
                                <div className="flex flex-1">
                                    <StaticProgressBar progress={campaign.donation_amount / campaign.donation_goal * 100} variant="primary" />
                                </div>
                            </div>
                            <div className="flex">
                                <Button onClick={handleAddDonation} variant="filled-tonal">
                                    + Adomány hozzáadása
                                </Button>
                            </div>
                            {donations.length > 0 && (
                                <>
                                    <div className="flex justify-between items-center">
                                        <span>Hibás utalások mutatása</span>
                                        <Switch
                                            value={showNotApproved}
                                            onChange={setShowNotApproved}
                                        />
                                    </div>
                                    <DonationsTable 
                                        donations={donations} 
                                        showNotApproved={showNotApproved}
                                        actions={(id) => (
                                            <IconButton onClick={() => handleDeleteDonation(id)} variant="error" compact>
                                                <TrashIcon />
                                            </IconButton>
                                        )}
                                        showEmail 
                                        showDonee
                                    />
                                </>
                            )}
                        </div>
                        <div className="flex flex-col gap-5">
                            <h1 className="ttl-l text-primary">Gyűjtések</h1>
                            <div className="flex">
                                <Button variant="filled" onClick={handleCreateCategory}>+ Kategória hozzáadása</Button>
                            </div>
                            {ambassadorCategories.map(({id, display_name, name}, index) =>(
                                <div key={id} className="flex flex-col gap-3">
                                    <div className="flex gap-1 items-center">
                                        <div className="flex flex-col">
                                            <span className="ttl-m -mb-1">{display_name}</span>
                                            <span className="lb-s text-on-surface-variant">{name}</span>
                                        </div>
                                        <IconButton 
                                            onClick={() => handleMoveCategory(id, "up")}
                                            disabled={index == 0}
                                            compact
                                        >
                                            <ChevronDown className="rotate-180" />
                                        </IconButton>
                                        <IconButton 
                                            onClick={() => handleMoveCategory(id, "down")}                                            
                                            disabled={index == ambassadorCategories.length - 1} 
                                            compact
                                        >
                                            <ChevronDown />
                                        </IconButton>
                                        <IconButton 
                                            onClick={(() => handleEditCategory(id))}
                                            compact
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton 
                                            onClick={() => handleDestroyCategory(id)} 
                                            variant="error" 
                                            compact
                                        >
                                            <TrashIcon />
                                        </IconButton>
                                    </div>
                                    <div className="flex">
                                        <Button variant="filled-tonal" onClick={handleAddAmbassador(id)}>{`+ ${name} hozzáadása`}</Button>
                                    </div>
                                    {(categorizedAmbassadors[id]?.length ?? 0) > 0 ? (
                                        <AmbassadorCards ambassadors={categorizedAmbassadors[id]} loading={ambassadorsLoading} />
                                    ) : (
                                        <span className="bd-s">Ehhez a kategóriához nem tartoznak gyűjtések.</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )
            }

        </div>
    );
}