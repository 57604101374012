import FacebookIcon from "@/svg/facebook.svg?react";
import InstragramIcon from "@/svg/instagram.svg?react";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import { ReturnSingle } from "@enymo/react-resource-hook";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Image } from "../Components/Image";
import NavbarButton from "../Components/NavbarButton";
import useRedirectOnAxiosError from "../Hooks/AxiosErrorRedirectHook";
import { ORGANIZATION_IMAGE_ASPECT_RATIO } from "../common";
import { PublicOrganization, usePublicOrganizations } from "../resources";

interface AlertProps {
    type: "error" | "success" | "warning";
    title?: string;
    message?: string;
}

function Alert({ type, title, message }: AlertProps) {
    return (
        <div className={`
            flex flex-col items-center gap-1 p-3
            ${type === "error" && "bg-error-container"}
            ${type === "success" && "bg-success-container"}
            ${type === "warning" && "bg-voneszo-orange [&_h3]:text-white [&_p]:text-white"}
        `}>
            {title && <h3 className="ttl-l">{title}</h3>}
            {message && <p className="bd-m">{message}</p>}
        </div>
    )
}

const [OrganizationProvider, useOrganization] = createRequiredContext<[PublicOrganization | null, ReturnSingle<PublicOrganization, PublicOrganization>]>("The OrganizationProvider must be present in the `public` component tree.");
const [AlertProvider, useSetAlert] = createRequiredContext<React.Dispatch<React.SetStateAction<AlertProps | null>>>("The AlertProvider must be provided to useAlert");
const useAlert = (alertProps: AlertProps | null) => {
    const setAlertProps = useSetAlert();
    useEffect(() => {
        setAlertProps(alertProps);
        return () => setAlertProps(null);
    }, [alertProps, setAlertProps]);
}

const [DonationSuccessfulProvider, useDonationSuccessful] = createRequiredContext<["success" | undefined, React.Dispatch<React.SetStateAction<"success" | undefined>>]>("DonationSuccessfulProvider has to be in the tree.");


export { useAlert, useDonationSuccessful, useOrganization };


export default function Public() {
    const { organizationId } = useParams();
    const [organization, actions] = usePublicOrganizations({
        id: Number(organizationId),
    })

    useRedirectOnAxiosError(actions.error);

    const [alertProps, setAlertProps] = useState<AlertProps | null>(null);
    const donationSuccess = useState<"success">();

    return (
        <OrganizationProvider value={[organization, actions]}>
            <div className="flex flex-col flex-1 bg-surface">
                <div className="flex bg-voneszo h-[70px] pr-4 shadow-lg shrink-0 relative z-10 gap-2 items-center">
                    <div className="flex grow justify-end items-center">
                        {organization ? (
                            <div className="absolute left-1 top-0">
                                <Image
                                    className="!rounded-b-[12px] !rounded-t-[0px] ml-0.5 h-[75px] bg-white shadow-sm"
                                    resourceImage={organization.image ?? undefined}
                                    aspectRatio={ORGANIZATION_IMAGE_ASPECT_RATIO}
                                    axisSet="y"
                                    to={`/public/organizations/${organization.id}`}
                                />
                            </div>
                        ) : <div />}
                        <div className="flex gap-3 items-center justify-between sm:justify-normal">
                            <NavbarButton to={organization?.website} linkType="new-tab" className="interactive-bg-surface-light">
                                <div className="flex gap1 items-center gap-1">
                                    <span>Honlapunk</span>
                                    <OpenInNewIcon className="h-[15px] fill-on-surface-light" />
                                </div>
                            </NavbarButton>
                            {organization?.facebook && (
                                <Link
                                    className="flex items-center justify-center interactive-bg-surface-light rounded-full p-[5px] shrink-0 size-8"
                                    to={organization.facebook}
                                    target="_blank"
                                >
                                    <FacebookIcon className="fill-on-surface-light size-7" />
                                </Link>
                            )}
                            {organization?.instagram && (
                                <Link
                                    to={organization.instagram}
                                    className="flex items-center justify-center interactive-bg-surface-light rounded-full p-[5px] shrink-0 size-8"
                                    target="_blank"
                                >
                                    <InstragramIcon className="fill-on-surface-light size-7" />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                <AlertProvider value={setAlertProps}>
                    <DonationSuccessfulProvider value={donationSuccess}>
                        <SimpleBar style={{ height: "100%" }}>
                            {alertProps && <Alert {...alertProps} />}
                            <main className="flex flex-col flex-1 h-full w-full px-5 sm:px-10">
                                <Outlet />
                            </main>
                        </SimpleBar>
                    </DonationSuccessfulProvider>
                </AlertProvider>
            </div>
        </OrganizationProvider>
    );
}