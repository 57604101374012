import { ReturnList } from "@enymo/react-resource-hook";
import { isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import { loadStripe } from "@stripe/stripe-js";
import { assert, DeepPartial } from "ts-essentials";

export const languages = ["hu", "en"] as const;
export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function toCurrency(amount?: string | number) {
    if (typeof amount === "string") {
        let convertedAmount = Number(amount);
        if (Number.isNaN(convertedAmount)) {
            return amount;
        }
        amount = convertedAmount;
    }
    return amount?.toLocaleString("hu-HU", {
        style: "currency",
        currency: "HUF",
        maximumFractionDigits: 0,
    });
}

export function addOpacity(color: string, opacity: number) {
    return `rgb(from var(--${color}) r g b / ${opacity});`
}

export function mixColors(color1: string, color2: string, percentage: number, semiColon: boolean = true) {
    return `color-mix(in srgb, var(--${color1}), var(--${color2}) ${percentage}%)${semiColon ? ";" : ""}`;
}

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);

export const CAMPAIGN_IMAGE_ASPECT_RATIO = 2;
export const AMBASSADOR_IMAGE_ASPECT_RATIO = 1;
export const ORGANIZATION_IMAGE_ASPECT_RATIO = 2.2;
export const ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO = 1.8;
export const ORGANIZATION_THANKING_IMAGE_ASPECT_RATIO = 1.8;

export function tailwindToHex(color: string) {
    const [r, g, b] = color.split(" ").map(x => parseInt(x))
    return "#" + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? "0" + hex : hex
    }).join("")
}

interface LinkedListNode {
    id: number;
    previous_id: number | null;
}

export type LinkedList = LinkedListNode[];

export function getLastLinkedListItem<T extends LinkedListNode>(list: T[]) {
    const elementsWithNext = new Set(list.map(({previous_id}) => previous_id));
    return list.find(({id}) => !elementsWithNext.has(id));
}

/**
 * Function to sort a linked list.
 */
export function sortLinkedList<T extends LinkedListNode>(list: T[]) {
    const sortedList: T[] = [];
    const idMap = new Map<number, T>(list.map(node => [node.id, node]));
    let currentNode = getLastLinkedListItem(list) ?? null;
    // The second condition is to prevent infinite loop if the list is not a linked list
    // and contains cycles. Mostly needed for local-only updates triggering useMemo.
    while (currentNode !== null && !sortedList.includes(currentNode!)) {
        sortedList.push(currentNode);
        currentNode = !isNotNull(currentNode.previous_id) ? null : idMap.get(currentNode.previous_id) ?? null;
    }
    return sortedList.reverse();
}

export function linkedListFindById<T extends LinkedListNode>(list: T[], id: number) {
    return list.find(node => node.id === id);
}

export function moveLinkedListResource<T extends LinkedListNode>(data: T[], update: ReturnList<T, T, null>["update"]) {
    return async (id: number | string, direction: "up" | "down") => {
        const initial = requireNotNull(data.find(node => node.id === id));
        const initialNext = data.find(node => node.previous_id === id);
        assert(direction === "up" || initialNext !== undefined, "Cannot move down if there is no next element");
        assert(direction === "down" || initial.previous_id !== null, "Cannot move up if there is no previous element");
        const toId = direction === "down" ? initialNext!.id : initial.previous_id;
        const [afterNext, afterPrevious] = direction === "down" ? [
            data.find(node => node.previous_id === toId),
            data.find(node => node.id === toId)
        ] : (() => {
            const afterNext = data.find(node => node.id === toId);
            return [
                afterNext,
                data.find(node => node.id === afterNext?.previous_id)
            ];
        })();

        if (isNotNull(initialNext)) {
            await update(initialNext.id, {
                previous_id: initial.previous_id
            } as DeepPartial<T>, "local-only");
        }
        if (isNotNull(afterNext)) {
            await update(afterNext.id, {
                previous_id: initial.id
            } as DeepPartial<T>, "local-only");
        }
        await update(initial.id, {
            previous_id: afterPrevious?.id ?? null
        } as DeepPartial<T>, "immediate");
        
    }
}

export function destroyLinkedListResource<T extends LinkedListNode>(
    data: T[], 
    destroy: ReturnList<T, T, null>["destroy"], 
    update: ReturnList<T, T, null>["update"],
) {
    return async (id: number | string) => {
        const initial = requireNotNull(data.find(node => node.id === id));
        const initialNext = data.find(node => node.previous_id === id);
        if (isNotNull(initialNext)) {
            await update(initialNext.id, {
                previous_id: initial.previous_id
            } as DeepPartial<T>, "local-only");
        }
        await destroy(initial.id, "immediate");
    }
}