import { AmbassadorCategory } from "@/js/resources";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../Form/Button";
import Input from "../Form/Input";
import Popup, { usePopup } from "../Popup";

interface Submit {
    name: string;
    display_name: string;
    previous_id: number | "first";
}

export default function NewAmbassadorCategoryPopup({ onSubmit, categories }: {
    onSubmit: (data: Submit) => void | Promise<void>,
    categories: AmbassadorCategory[],
}) {
    const form = useForm<Submit>();
    const { popPopup } = usePopup();

    return (
        <Popup title="Nagykövet hozzáadása" form={form} onSubmit={onSubmit} className="w-full" buttons={[
            <Button variant="text" onClick={popPopup}>Mégse</Button>,
            <Button variant="filled-tonal" submit>Mentés</Button>
        ]}>
            <div className="flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-3">
                    <Input label="Név" name="name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Megjelenített név" name="display_name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input
                        label="Sorrenben előtte"
                        name="previous_id"
                        type="select"
                        selectClassName="[&_option]:bg-surface [&_option]:text-on-surface"
                        choices={[
                            {
                                label: "Első elem",
                                value: "first",
                            },
                            ...categories.map((category) => ({
                                label: `${category.name} (${category.display_name})`,
                                value: category.id.toString(),
                            }))
                        ]}
                    />
                </div>
            </div>
        </Popup>
    )
}