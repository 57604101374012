import React from "react";
import Button from "../Form/Button";
import Popup from "../Popup";

export default function AlertPopup({ children, onCancel, }: {
    children: React.ReactNode,
    onCancel: () => void,
}) {

    return (
        <Popup buttons={[
            <Button className="interactive-bg-surface-container" variant="text" onClick={onCancel}>Rendben</Button>
        ]} className="w-full">
            {children}
        </Popup>
    )
}